.box {
    width: 800px; /* Adjust width as needed */
    background-color: white; /* Example background color */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loginInput fieldset
  {
    border: 0;
  }
  @media (max-width:899.1px){
  .box {
  width: 400px;
  }
}