.box {
  width: 800px;
  /* Adjust width as needed */
  background-color: white;
  /* Example background color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width:899.1px) {
  .box {
    width: 400px;
  }
}

.otp-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-input-container {
  margin-bottom: 30px;
  margin-top: 30px;

}



.otp-input-field {
  background-color: #dddddd72;
  height: 40px;
  margin: 0 5px;
  font-size: 10px;
  text-align: center;
  border: 1px solid #dddddd72;
  border-radius: 5px;
  /* Example Add border radius */
}

.submit-button {
  /* Add styling for the submit button */
  padding: 10px 20px;
  /* Example: Add padding */
  font-size: 16px;
  /* Example: Increase font size */
  background-color: #007bff;
  /* Example: Set background color */
  color: #fff;
  /* Example: Set text color */
  border: none;
  /* Example: Remove border */
  border-radius: 4px;
  /* Example: Add border radius */
  cursor: pointer;
  /* Example: Add pointer cursor */
}

.submit-button:hover {
  background-color: #0056b3;
  /* Example: Change background color on hover */
}